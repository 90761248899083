.step-title {
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  .order-number {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    font-size: 24px;
    font-weight: 700;
    color: #15cd48;
    border: 3px solid #15cd48;
    border-radius: 50%;
  }
}
