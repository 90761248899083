@import '../variables/variables';
@import '../mixins/mixins';

.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 790px;
  padding: 16px 32px;
  border: 1px dashed $secondary;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px 4px;
  }

  .question {
    line-height: calculateRem(36px);
  }
}
