@import '../variables/variables';
@import '../mixins/mixins';
.card {
  background: #fff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
}

.card-category {
  position: relative;
  width: calc(50% - 16px);
  height: 140px;
  padding: 16px;

  @media (max-width: 420px) {
    width: 100%;
  }

  &:nth-child(odd) {
    margin-right: 32px;
  }

  .category-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      height: auto;
      width: 100%;
    }
  }
}

.card-suppliers {
  position: relative;
  display: flex;
  transition: 0.3s;
  margin-bottom: 32px;
  width: 100%;
  &:hover {
    background: $light-green;
  }

  .card-image {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    @media (max-width: 530px) {
      width: 120px;
      height: 120px;
    }

    @media (max-width: 440px) {
      width: 100%;
      height: 200px;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        165.4deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.2) 100%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .card-info {
    width: calc(100% - 200px);
    padding: 22px;

    @media (max-width: 530px) {
      width: calc(100% - 120px);
      padding: 6px 10px;
    }

    @media (max-width: 440px) {
      width: 100%;
      padding: 10px 12px;
    }
  }

  .card-title {
    font-weight: 900;
    @include fontSize(22px);
  }

  .price {
    font-weight: 900;
    @include fontSize(28px);
    line-height: 28px;
  }

  .old-price {
    position: relative;
    @include fontSize(28px);
    font-weight: 700;

    &::before {
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      height: 3px;
      width: 100%;
      background: $red;
    }
  }
}

.card-checkout {
  padding: 30px 22px;

  .item {
    max-width: 180px;
  }

  .item-image {
    width: 40px;
    height: 40px;
  }

  .item-description {
    padding-left: 10px;
    max-width: calc(100% - 40px);
  }

  .map-block {
    pointer-events: none;
    margin-left: -22px;
    margin-right: -22px;
    margin-bottom: -30px;
  }
}

.card-truck-type {
  min-height: 100px;
  padding: 0 16px;

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0 16px 16px 16px;
  }

  .truck-img {
    width: 100px;
    @media (max-width: 520px) {
      width: 70px;
    }
  }
}

.card-secondary {
  background-color: #fcfcfc !important;
  padding: 16px;

  .product-radio {
    background-color: #fff;
    max-width: 370px;
  }
}
