@import '../variables/variables';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 352px;
  height: 100%;
  padding: 80px 0 28px 0;
  overflow-y: auto;
  z-index: 3;
  background: $secondary-light;

  @media (max-width: 1200px) {
    width: 280px;
  }

  @media (max-width: 768px) {
    top: 60px;
    left: 0;
    width: 100%;
    height: auto;
    padding: 10px 0;
  }
}

.nav-desktop,
.nav-mobile {
  counter-reset: order;
}
