@import '../variables/variables';
@import '../mixins/mixins';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  margin-bottom: calculateRem(24px);
}
h2 {
  margin-bottom: calculateRem(20px);
}
h3 {
  margin-bottom: calculateRem(18px);
}
h4 {
  margin-bottom: calculateRem(16px);
}
h5 {
  margin-bottom: calculateRem(14px);
}
h6 {
  margin-bottom: calculateRem(12px);
}

.h1 {
  font-weight: 600;
  @include fontSize(24px);
}

.h2 {
  font-weight: 700;
  @include fontSize(18px);
}

.body-text-1 {
  @include fontSize(18px);
  line-height: calculateRem(24px);

  @media (max-width: 768px) {
    @include fontSize(16px);
  }
}

.body-text-2 {
  @include fontSize(28px);
  line-height: 28px;
}

.body-text-3 {
  @include fontSize(20px);
}

.body-text-4 {
  @include fontSize(24px);
}

.body-text-5 {
  @include fontSize(14px);
}

.body-text-6 {
  @include fontSize(12px);
  line-height: 15px;
}

.body-text-7 {
  @include fontSize(24px);
}

.body-text-8 {
  @include fontSize(16px);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-normal {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-extra-bold {
  font-weight: 900;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-end {
  text-align: end;
}

.pre {
  white-space: pre;
}
