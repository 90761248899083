.info-bar {
  //display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 0 40px;
  //transform: translateY(-100%);
  background: #15cd48;
  transition: 0.2s;
  z-index: 4;

  @media (max-width: 768px) {
    height: 60px;
  }
}
