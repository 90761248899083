.suppliers-step {
  .error {
    min-height: 20px;
  }

  .product-image {
    width: 96px;
    height: 96px;
    overflow: hidden;

    @media (max-width: 580px) {
      width: 56px;
      height: 56px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .content {
    padding-left: 34px;
    width: calc(100% - 200px);

    @media (max-width: 580px) {
      padding-left: 16px;
      width: calc(100% - 56px);

      .min-height-mobile {
        min-height: 112px;
      }

      .left-centered-mobile {
        width: calc(100% + 72px);
        margin-left: -72px;
      }
    }
  }

  .product-suppliers {
    border: 1px solid rgba(0, 0, 0, 0.035);
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.01) 0,
      rgba(0, 0, 0, 0.01) 50%,
      rgba(0, 0, 0, 0.02) 50%,
      rgba(0, 0, 0, 0.01) 100%
    );
    padding: calculateRem(16px);
  }

  .small-icon {
    font-size: calculateRem(16px) !important;
  }
}
