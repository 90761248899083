@import '../mixins/mixins';

.form-input {
  width: 100%;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  &.longer {
    min-width: 16rem;
    flex: 4;
  }

  &.shorter {
    flex: 1;
    min-width: 8rem;
  }

  &.search {
    .input-icon {
      top: 14px;
    }
  }

  &.with-textarea {
    textarea {
      resize: none;
    }
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 18px 40px 18px 22px;
    border: 1px solid $light-grey;
    @include fontSize(16px);

    &::placeholder {
      color: $secondary;
    }
  }

  .input-icon {
    position: absolute;
    top: 46px;
    right: 16px;
    display: inline-block;
    width: 24px;
    height: 24px;
    pointer-events: none;

    @media (max-width: 1200px) {
      top: 42px;
    }

    &.email {
      background: url('../../assets/icons/email-input.svg') center center no-repeat;
    }

    &.user {
      background: url('../../assets/icons/user-input.svg') center center no-repeat;
    }

    &.business {
      background: url('../../assets/icons/business-input.svg') center center no-repeat;
    }

    &.vat {
      background: url('../../assets/icons/vat-input.svg') center center no-repeat;
    }

    &.location {
      background: url('../../assets/icons/location-light.svg') center center no-repeat;
    }

    &.phone {
      background: url('../../assets/icons/phone-input.svg') center center no-repeat;
    }

    &.message {
      background: url('../../assets/icons/message.svg') center center no-repeat;
    }

    &.search {
      background: url('../../assets/icons/search.svg') center center no-repeat;

      &.light {
        background: url('../../assets/icons/search-light.svg') center center no-repeat;
      }
    }

    &.password {
      cursor: pointer;
      background: url('../../assets/icons/password-input.svg') center center no-repeat;
    }

    &.dropdown {
      background: url('../../assets/icons/dropdown-icon.svg') center right no-repeat;

      &.active {
        transform: rotate(180deg);
      }
    }

    &.show {
      &.password {
        cursor: pointer;
        background: url('../../assets/icons/password-input-show.svg') center center no-repeat;
      }
    }
  }

  &.required {
    .label {
      display: inline-block;
      position: relative;

      &:after {
        content: '*';
        position: absolute;
        top: 0;
        right: -12px;
        left: auto;
        color: $red;
      }
    }
  }

  .error-msg {
    display: inline-block;
    min-height: 16px;
  }

  &.error {
    input {
      border: 1px solid $red;
    }

    .error-msg {
      color: $red;
      @include fontSize(13px);
    }
  }
}

.form-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;

  span {
    @include fontSize(16px);
    color: $secondary;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: url('../../assets/icons/radiobutton-checked.svg') center center no-repeat;
      background-size: cover;
    }

    &:checked ~ span {
      color: $primary;
    }
  }
}

.address-radio {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .checkmark {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $secondary;
    background: $light-grey;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
      background-size: cover;
    }

    &:checked ~ span {
      color: $green;
    }
  }
}

.product-radio {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 386px;
  height: 100px;
  border: 2px solid $light-grey;

  &.has-hidden {
    &:nth-child(n + 7) {
      display: none;
    }
  }

  @media (max-width: 420px) {
    width: 100%;
  }

  .distance {
    position: absolute;
    width: 100px;
    background: $orange;
    color: #fff;
  }

  .product-image {
    display: inline-block;
    width: 100px;
    height: 96px;
    overflow: hidden;
    cursor: pointer;

    img {
      height: 96px;
      width: 100px;
      object-fit: cover;
    }
  }

  .label {
    width: calc(100% - 100px);
  }

  .checkmark {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: $light-grey;
      box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
      overflow: hidden;
    }

    .content {
      height: 100%;
      width: 100%;
      padding: 4px 56px 4px 14px;

      .desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: $light-green;

      &:before {
        background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
        background-size: cover;
      }
    }
  }
}

.banks-radio,
.pay-later-radio {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 148px;
  height: 60px;

  @media (max-width: 420px) {
    width: 100%;
  }
  .checkmark {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: 1px solid $light-grey;
    height: 60px;

    img {
      max-height: 54px;
      max-width: 140px;
      object-fit: contain;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border: 3px solid $green;
    }
  }
}

.pay-later-radio {
  max-width: none;

  .checkmark {
    padding-left: 20px;
    align-items: start;
  }
}

.payment-radio {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 386px;
  height: 85px;

  @media (max-width: 420px) {
    width: 100%;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 26px 12px 26px 68px;
    cursor: pointer;
    border: 1px solid $light-grey;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 20px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: $light-grey;
      box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
      overflow: hidden;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: $light-green;
      border: 1px solid $green;

      &:before {
        background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
        background-size: cover;
      }
    }
  }

  .payment-logo {
    display: flex;
    align-items: center;
  }
}

.category-radio {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  width: 100%;
  height: 100%;
  background: linear-gradient(75.8deg, rgba(0, 0, 0, 0.8) 0%, rgba(23, 24, 24, 0) 100%);

  .checkmark {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #fff;
    overflow: hidden;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border: none;
      background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
      background-size: cover;
    }
  }
}

.package-radio {
  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    height: 40px;
    border: 3px solid $light-grey;
    border-radius: 40px;
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border: 3px solid $green;
    }
  }
}

.supplier-radio {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: -1px;
  padding: 32px 16px;
  border: 1px solid $light-grey;
  background: #fff;
  overflow: hidden;
  z-index: 1;

  &:first-of-type {
    border: 1px solid $orange;
    z-index: 2;
  }

  .best-offer {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 6px;
    background: $orange;
  }

  &.disabled {
    pointer-events: none;
    background: $light-grey;
  }

  .closed-label {
    display: none;

    position: absolute;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);

    &.visible {
      display: inline;
    }
  }

  .checkmark {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 36px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 16px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: $light-grey;
      box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
      overflow: hidden;
    }

    .left {
      min-width: 60%;
    }

    .right {
      min-width: 40%;
    }

    .title {
      display: block;
      width: 145px;
      color: $primary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .price-full {
      @include fontSize(22px);
    }

    .price-discount {
      @include fontSize(22px);
    }

    .old-price {
      display: inline-block;
      position: relative;
      @include fontSize(18px);
      line-height: 24px;
      font-weight: 700;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 3px;
        background: $red;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    //.old-price {
    //  position: relative;
    //  @include fontSize(18px);
    //  font-weight: 700;
    //
    //  &::before {
    //    left: 0;
    //    transform: translateY(-50%);
    //    display: inline-block;
    //    height: 3px;
    //    width: 100%;
    //    background: $red;
    //  }
    //}

    .product-image {
      display: inline-block;
      width: 85px;
      height: 100%;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      &:before {
        background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
        background-size: cover;
      }
    }
  }

  .product-delivery-time {
    font-size: 12px;
  }
}

.form-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  height: 24px;
  cursor: pointer;

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: $light-grey;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    overflow: hidden;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: url('../../assets/icons/checkboxe-checked.svg') center center no-repeat;
      background-size: cover;
    }
  }

  &.rounded {
    height: 32px;
    padding-left: 38px;
    color: $secondary;

    .checkmark {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    input {
      &:checked ~ span {
        color: $green;
      }
    }

    &.category {
    }
  }
}

.form-checkbox-switch {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 56px;
  height: 32px;
  cursor: pointer;

  .checkmark {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    height: 32px;
    width: 56px;
    background: $light-grey;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $green;
      transition: 0.2s;
      transform: translate(5px, -50%);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: $green-2;

      &:after {
        transform: translate(27px, -50%);
      }
    }
  }
}

.map-input {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 40px);
  transform: translate(-50%, 20px);
}

.product-input-number {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 208px;
  height: 60px;
  background: $light-grey-2;
  border-radius: 40px;

  @media (max-width: 420px) {
    width: 198px;
    height: 50px;
  }

  button {
    padding: 0 16px;
    @include fontSize(28px);
    font-weight: 500;

    &:disabled {
      color: $secondary;
    }
  }

  .value {
    @include fontSize(26px);
    display: flex;
    align-items: center;
  }

  input {
    font-weight: 900;
    width: 90px;
    background: $light-grey-2;
    text-align: center;

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.select-input {
  max-width: none;

  .select-box {
    height: 52px;
    padding: 0 22px;
    border: 1px solid $light-grey;
  }

  .options-box {
    position: absolute;
    top: 96px;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    z-index: 1;

    .option {
      cursor: pointer;
      padding: 16px 20px;

      &:hover {
        background: $light-green;
      }
    }
  }
}

.pac-container {
  z-index: 1400;
}
