.thank-you-step {
  width: 100vw;
  min-height: 100vh;
  padding: 20px 20px 60px 20px;

  .content {
    width: 100%;
    max-width: 640px;
  }

  .step-image {
    width: 150px;

    @media (max-width: 768px) {
      width: 100px;
    }
  }

  .title {
    font-size: 60px;

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
}
