.checkout-step {
  .content {
    .left {
      width: 100%;
      max-width: 380px;

      @media (max-width: 1040px) {
        max-width: 320px;
      }

      @media (max-width: 420px) {
        max-width: none;
      }
    }

    .right {
      width: 100%;
      max-width: 320px;

      @media (max-width: 420px) {
        max-width: none;
      }

      .old-piece-price {
        display: inline-block;
        position: relative;
        padding: 0 2px;
        margin: 0 4px;

        &:before {
          content: '';
          position: absolute;
          top: 52%;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 1px;
          background: $red;
        }
      }
    }
  }

  .banks-list,
  .pay-later-list {
    max-width: 328px;
  }
}
