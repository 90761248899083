.delivery-step {
  .summary {
    padding: 16px 50px 0 50px;
    background: $light-green;

    @media (max-width: 768px) {
      padding: 16px 20px 0 20px;
    }
  }
}
