/* TEMP CSS START */

.supplier-radio .checkmark {
  flex-wrap: initial;
}

.with-icon.business,
.with-icon.delivery-grey {
  padding: 4px 0 4px 30px;
}

.with-icon.business {
  margin-top: 0;
}

.supplier-radio.best-offer {
  padding-top: 30px;
}

.btn-sidebar.finished:before {
  background: url('../../assets/icons/checkboxe-checked.svg') 50% no-repeat;
  border-radius: 50%;
  background-size: cover;
}

@media (max-width: 768px) {
  .supplier-radio {
    padding: 30px 10px 10px;
  }

  .supplier-radio .checkmark {
    padding-left: 0;
    padding-right: 0;
  }

  .supplier-radio .checkmark .left {
    min-width: 70%;
  }

  .supplier-radio .checkmark .right {
    min-width: 30%;
  }

  .supplier-radio .checkmark:before {
    top: 15px;
    left: initial;
    right: 10px;
    width: 18px;
    height: 18px;
  }

  .with-icon.business,
  .with-icon.delivery-grey {
    padding: 4px 0 4px 25px;
    background-size: 15px;
  }

  .pricing .body-text-2 {
    font-size: 17px;
    line-height: 140%;
  }

  .m-b-32 {
    margin-bottom: 0.7rem;
  }

  .m-b-60 {
    margin-bottom: 2.5rem;
  }

  .step-title .order-number {
    width: 35px;
    height: 35px;
    margin-right: 12px;
    font-size: 14px;
  }

  .steps-wrapper {
    width: 100vw;
  }

  .address-radio .checkmark {
    width: 22px;
    height: 22px;
  }

  .address-step .select-address {
    margin-right: 0;
  }

  .address-step .address-item {
    max-width: 100%;
    height: 170px;
    margin-right: 32px;
    padding: 50px 16px 16px;

    &:not(.add-address) {
      display: block;
    }

    .address {
      height: 44px;
      overflow: hidden;
      margin-bottom: 0.5rem;
    }

    .comment {
      height: 58px;
      overflow: hidden;
    }
  }

  .address-step .address-item.add-address {
    padding-bottom: 0;
  }

  .address-step .address-item.add-address:before {
    top: 35%;
  }

  .address-step .address-item.add-address .m-t-96 {
    margin-top: 0;
  }

  .suppliers-step .content .m-b-60 {
    margin-bottom: 0;
  }

  .delivery-step .summary {
    padding: 10px 10px 0;
    flex-direction: column;
  }

  .checkout-step .content .left {
    order: 1;
  }

  .checkout-step .content .right {
    order: 0;
    margin-bottom: 2.5rem;
  }
}

/* TEMP CSS END */
