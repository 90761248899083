.steps-nav {
  position: fixed;
  bottom: 28px;
  left: calc(50% + 176px);
  right: auto;
  width: 100%;
  max-width: 846px;
  padding: 28px;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
  z-index: 4;

  @media (max-width: 1200px) {
    max-width: 50vw;
    left: calc(50% + 140px);
  }

  @media (max-width: 768px) {
    bottom: 20px;
    max-width: 90vw;
    left: 50%;
  }

  @media (max-width: 480px) {
    padding: 16px;
  }
}
