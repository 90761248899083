@import '../mixins/mixins';

.m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: calculateRem(1px);
}

.p-4 {
  padding: calculateRem(4px);
}

.p-8 {
  padding: calculateRem(8px) !important;
}

.p-16 {
  padding: calculateRem(16px);
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.m-l-2 {
  margin-left: calculateRem(2px);
}

.m-l-4 {
  margin-left: calculateRem(4px);
}

.m-l-6 {
  margin-left: calculateRem(6px);
}

.m-l-8 {
  margin-left: calculateRem(8px);
}

.m-l-10 {
  margin-left: calculateRem(10px);
}

.m-l-12 {
  margin-left: calculateRem(12px);
}

.m-l-16 {
  margin-left: calculateRem(16px);
}

.m-l-20 {
  margin-left: calculateRem(20px);
}

.m-l-24 {
  margin-left: calculateRem(24px);
}

.m-l-34 {
  margin-left: calculateRem(34px);
}

.m-l-42 {
  margin-left: calculateRem(42px);
}

.m-r-2 {
  margin-right: calculateRem(2px);
}

.m-r-4 {
  margin-right: calculateRem(4px);
}

.m-r-6 {
  margin-right: calculateRem(6px);
}

.m-r-8 {
  margin-right: calculateRem(8px);
}

.m-r-12 {
  margin-right: calculateRem(12px);
}

.m-r-14 {
  margin-right: calculateRem(14px);
}

.m-r-16 {
  margin-right: calculateRem(16px);
}

.m-r-18 {
  margin-right: calculateRem(18px);
}

.m-r-20 {
  margin-right: calculateRem(20px);
}

.m-r-24 {
  margin-right: calculateRem(24px);
}

.m-r-28 {
  margin-right: calculateRem(28px);
}

.m-r-32 {
  margin-right: calculateRem(32px);
}

.m-t-8 {
  margin-top: calculateRem(8px);
}

.m-t-16 {
  margin-top: calculateRem(16px);
}

.m-t-32 {
  margin-top: calculateRem(32px);
}

.m-t-96 {
  margin-top: calculateRem(96px);
}

.m-b-2 {
  margin-bottom: calculateRem(2px);
}

.m-b-4 {
  margin-bottom: calculateRem(4px);
}

.m-b-6 {
  margin-bottom: calculateRem(6px);
}

.m-b-8 {
  margin-bottom: calculateRem(8px);
}

.m-b-12 {
  margin-bottom: calculateRem(12px);
}

.m-b-16 {
  margin-bottom: calculateRem(16px);
}

.m-b-20 {
  margin-bottom: calculateRem(20px);
}

.m-b-18 {
  margin-bottom: calculateRem(18px);
}

.m-b-22 {
  margin-bottom: calculateRem(22px);
}

.m-b-24 {
  margin-bottom: calculateRem(24px);
}

.m-b-28 {
  margin-bottom: calculateRem(28px);
}

.m-b-30 {
  margin-bottom: calculateRem(32px);
}

.m-b-32 {
  margin-bottom: calculateRem(32px);
}

.m-b-38 {
  margin-bottom: calculateRem(38px);
}

.m-b-44 {
  margin-bottom: calculateRem(44px);
}

.m-b-48 {
  margin-bottom: calculateRem(48px);
}

.m-b-60 {
  margin-bottom: calculateRem(60px);
}

.m-b-76 {
  margin-bottom: calculateRem(76px);
}

.m-b-100 {
  margin-bottom: calculateRem(100px);
}

.m-b-120 {
  margin-bottom: calculateRem(120px);
}

.p-b-32 {
  padding-bottom: calculateRem(32px);
}

.p-r-6 {
  padding-right: calculateRem(6px);
}

.p-r-14 {
  padding-right: calculateRem(14px);
}

.p-r-16 {
  padding-right: calculateRem(16px);
}

.p-t-68 {
  padding-top: calculateRem(68px);
}

.px-14 {
  padding-left: calculateRem(14px);
  padding-right: calculateRem(14px);
}

.px-32 {
  padding-left: calculateRem(32px);
  padding-right: calculateRem(32px);
}

.px-38 {
  padding-left: calculateRem(38px);
  padding-right: calculateRem(38px);
}

.py-10 {
  padding-top: calculateRem(10px);
  padding-bottom: calculateRem(10px);
}

.py-12 {
  padding-top: calculateRem(12px);
  padding-bottom: calculateRem(12px);
}

.py-14 {
  padding-top: calculateRem(14px);
  padding-bottom: calculateRem(14px);
}

.py-16 {
  padding-top: calculateRem(16px);
  padding-bottom: calculateRem(16px);
}

.py-18 {
  padding-top: calculateRem(18px);
  padding-bottom: calculateRem(18px);
}

.py-24 {
  padding-top: calculateRem(24px);
  padding-bottom: calculateRem(24px);
}

.py-30 {
  padding-top: calculateRem(30px);
  padding-bottom: calculateRem(30px);
}

.py-32 {
  padding-top: calculateRem(32px);
  padding-bottom: calculateRem(32px);
}

.py-38 {
  padding-top: calculateRem(38px);
  padding-bottom: calculateRem(38px);
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative;
}

.no-scroll {
  overflow-y: hidden;
  height: 100vh;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.opacity-hover {
  &:hover {
    opacity: 0.7;
  }
}

.text-underlined {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.no-outline {
  outline: none;

  &:focus {
    outline: none;
  }
}

.capitalize {
  text-transform: capitalize;
}

.new-line-mobile {
  @media (max-width: 1024px) {
    display: block;
  }
}

.rounded {
  border-radius: 50%;
}

.col-gap-12 {
  column-gap: calculateRem(12px);
}
