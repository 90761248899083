@import '../variables/variables';
@import '../mixins/mixins';

.btn-contained {
  position: relative;
  padding: 12px 24px;
  @include fontSize(20px);
  font-weight: 700;
  color: #fff;
  background-color: $green;
  border: 3px solid $green;

  &.with-arrow {
    padding: 12px 64px 12px 24px;

    &:before {
      background-image: url('../../assets/icons/angle-white.svg');
    }

    &:hover {
      &:before {
        width: 18px;
        transform: translateX(8px) translateY(-50%);
        background-image: url('../../assets/icons/arrow-white.svg');
      }
    }

    &.angle-arrow {
      &:hover {
        &:before {
          width: 13px;
          transform: translateX(8px) translateY(-50%);
          background-image: url('../../assets/icons/angle-white.svg');
        }
      }

      &.reversed {
        padding: 12px 24px 12px 64px;

        @media (max-width: 480px) {
          padding: 12px 24px;

          &:before {
            display: none;
          }
        }

        &:before {
          content: '';
          left: 32px;
          right: auto;
          background-image: url('../../assets/icons/angle-white.svg');
          background-position: center left;
          transform: translateY(-50%) rotate(180deg);
        }

        &:hover {
          &:before {
            transform: translateY(-50%) translateX(-8px) rotate(180deg);
          }
        }
      }
    }
  }

  &.light {
    background-color: #fff;
    color: $green;

    &.with-arrow {
      &:before {
        background-image: url('../../assets/icons/angle-green.svg');
      }

      &:hover {
        &:before {
          width: 18px;
          transform: translateX(8px) translateY(-50%);
          background-image: url('../../assets/icons/arrow-green.svg');
        }
      }
    }
  }
}

.btn-outlined {
  position: relative;
  padding: 12px 24px;
  @include fontSize(20px);
  font-weight: 700;
  color: $primary;
  background-color: transparent;
  border: 3px solid $green;
}

.with-arrow {
  padding: 12px 64px 12px 24px;

  &:disabled {
    pointer-events: none;
    background: $secondary;
    border-color: $secondary;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: auto;
    right: 24px;
    background-image: url('../../assets/icons/angle-green.svg');
    background-repeat: no-repeat;
    background-position: center right;
    transition: 0.2s;
    transition-property: transform, width;
    transform: translateY(-50%);
  }

  &:hover {
    &:before {
      width: 18px;
      transform: translateX(8px) translateY(-50%);
      background-image: url('../../assets/icons/arrow-green.svg');
    }
  }

  &.angle-arrow {
    @media (max-width: 480px) {
      padding: 12px 24px;

      &:before {
        display: none;
      }

      &.btn-steps {
        padding: 6px 16px;
        font-size: 16px;
      }
    }

    &:hover {
      &:before {
        width: 13px;
        transform: translateX(8px) translateY(-50%);
        background-image: url('../../assets/icons/angle-green.svg');
      }
    }
  }

  &.reversed {
    padding: 12px 24px 12px 64px;

    &:disabled {
      color: #fff;

      &:before {
        background-image: url('../../assets/icons/angle-white.svg');
      }
    }

    @media (max-width: 480px) {
      padding: 12px 24px;

      &:before {
        display: none;
      }
    }

    &:before {
      left: 32px;
      right: auto;
      background-position: center left;
      transform: translateY(-50%) rotate(180deg);
    }

    &:hover {
      &:before {
        transform: translateY(-50%) translateX(-8px) rotate(180deg);
      }
    }
  }
}

.btn-search {
  width: 28px;
  height: 28px;
  background: url('../../assets/icons/search.svg') center center no-repeat;
}

.btn-web-site {
  color: #fff;
  @include fontSize(16px);
  padding: 6px 10px;
  transition: 0.2s;

  @media (max-width: 768px) {
    padding: 3px 8px;
  }

  &:hover {
    background: $green;
    color: #ffffff;
  }

  &.dark {
    color: $primary;

    &:hover {
      background: $green;
      color: #ffffff;
    }
  }
}

.btn-pagination {
  width: 48px;
  height: 48px;
  background: url('../../assets/icons/btn-pagination.svg');

  &.forward {
    transform: rotate(180deg);
  }
}

.btn-close-info {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  display: inline-block;
  background: url('../../assets/icons/close-light.svg') center center no-repeat;
  background-size: contain;

  @media (max-width: 480px) {
    right: 20px;
  }
}

.btn-switch {
  width: 50%;
  padding: 12px 0;
  @include fontSize(16px);
  font-weight: 500;
  border-radius: 100px;
  color: $secondary;

  &.active {
    color: #fff;
    background: $green;
  }
}

.btn-logout {
  padding: 4px 42px 4px 0;
  @include fontSize(18px);
  font-weight: 700;
  color: $secondary;
  background: url('../../assets/icons/btn-logout.svg') center right no-repeat;
}

.btn-action {
  width: 24px;
  height: 24px;
  display: inline-block;

  &.edit {
    background: url('../../assets/icons/edit.svg') center center no-repeat;
  }

  &.remove {
    background: url('../../assets/icons/remove.svg') center center no-repeat;
  }
}

.btn-close {
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/close-red.svg') center center no-repeat;

  &:disabled {
    background: url('../../assets/icons/close.svg') center center no-repeat;
  }
}

.add-truck {
  position: relative;
  width: 100%;
  padding: 25px;
  border: 3px dashed $light-grey-3;

  &:before {
    content: '';
    width: 50px;
    height: 50px;
    margin-right: 16px;
    background: url('../../assets/icons/plus-green.svg') center center no-repeat;
  }

  &:disabled {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.btn-close-modal {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/icons/close.svg') center center no-repeat;
}

.btn-sidebar {
  display: block;
  position: relative;
  width: 100%;
  padding: 28px;
  text-align: left;
  font-size: 18px;
  color: #7b7b7b;

  @media (max-width: 1200px) {
    padding: 26px 28px;
  }

  &:before {
    counter-increment: order;
    content: counter(order);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border: 2px solid $secondary;
    border-radius: 50%;
    color: $secondary;
  }

  .step-value {
    display: none;
    position: absolute;
    top: 55px;
    left: 76px;
    width: 200px;
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: pre;
  }

  &.active {
    color: $green;
    background: #fff;

    &:before {
      color: inherit;
      border: 2px solid $green;
    }
  }

  &.finished {
    color: $primary;

    &:before {
      color: transparent;
      border: 2px solid $green;
      background: url('../../assets/icons/finished-step.svg') center center no-repeat;
    }

    .step-value {
      display: block;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.mobile {
    padding: 0 14px;
    font-size: 13px;

    &:before {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }

    &.active {
      background: none;
    }
  }
}

.btn-see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  text-align: center;
  padding-right: 24px;

  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    background: url('../../assets/icons/angle-green-bottom.svg') center center no-repeat;
  }

  &.active {
    .icon {
      background: url('../../assets/icons/angle-green-top.svg') center center no-repeat;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: calc(50% - 75px);
    height: 1px;
    background: $light-grey;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    display: inline-block;
    width: calc(50% - 55px);
    height: 1px;
    background: $light-grey;
  }
}

.btn-address {
  background-color: #fff !important;
  border: 1px solid $light-grey !important;

  &.selected {
    z-index: 2;
    border: 0 !important;
    box-shadow: 0px 4px 40px 0px #00000026;
  }
}
