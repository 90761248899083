$primary: #171818;
$secondary: #7b7b7b;
$secondary-light: #f3f3f3;
$light-grey: #e0e0e0;
$light-grey-2: #efefef;
$light-grey-3: #bdbdbd;
$green: #15cd48;
$green-2: #5ef387;
$light-green: #f4fcf9;
$red: #e03636;
$yellow: #ffb802;
$orange: #fb842e;
