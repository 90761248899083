@import '../variables/variables';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  touch-action: pan-y !important;
}

html,
body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff !important;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: $green;
  outline: none;
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

input {
  padding: 0;
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  &.with-style {
    list-style-type: disc;
    list-style-position: inside;
  }
}

img {
  width: 100%;
  height: auto;
}

hr {
  display: block;
  border-color: $light-grey;
  border: 1px solid $light-grey;
  color: $light-grey;
}
