.timing-step {
  .contacts {
    .time-table {
      width: 316px;

      .table-item {
        border-top: 1px solid $light-grey;

        &:last-of-type {
          border-bottom: 1px solid $light-grey;
        }
      }
    }

    .company-contacts {
      max-width: 280px;

      .nav-link {
        width: 100%;
      }
    }
  }

  .summary {
    padding: 16px 50px 0 50px;
    background: $light-green;

    @media (max-width: 768px) {
      padding: 16px 20px 0 20px;
    }
  }

  .date {
    width: calc(100% - 60px);
    padding-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    //overflow-x: scroll;

    .slick-arrow {
      position: absolute;
      top: 0;
      width: 30px;
      height: 72px;
      padding: 0;
      font-size: 0;

      &.slick-prev {
        left: 0;
        transform: translateX(-100%) rotate(180deg);
        background: url('../../assets/icons/angle-green.svg') center center no-repeat;
        background-size: 50px;
      }

      &.slick-next {
        right: 0;
        transform: translateX(100%);
        background: url('../../assets/icons/angle-green.svg') center center no-repeat;
        background-size: 50px;
      }

      &.slick-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .item {
      width: 60px;
      height: 72px;
      padding-top: 3px;
      border: 3px solid transparent;

      &.active {
        border: 3px solid $green;
      }
    }
  }

  .time-slots {
    margin-right: -32px;
    .item {
      width: 172px;
      height: 64px;
      border: 2px solid $light-grey-3;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 155px;
      }

      .delivery-price {
        color: $green;
      }

      &.active {
        background: $green;
        color: #fff;
        border: 2px solid $green;

        .delivery-price {
          color: #fff;
        }
      }

      &.disabled {
        color: $light-grey-3;
        pointer-events: none;
        cursor: auto;

        .delivery-price {
          color: $light-grey-3;
        }
      }
    }
  }

  .no-time-slot {
    .no-time-img {
      max-width: 120px;
    }
  }

  .weekend,
  .weekend * {
    pointer-events: none;
    color: #eee;
    border-color: #eee;
  }
}
