@import '../variables/variables';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 28px;
  background: #fff;
  z-index: 4;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    height: 60px;
    padding: 0 16px;
    background: $secondary-light;
    box-shadow: none;
    border-bottom: 1px solid $light-grey;
  }

  .logo {
    max-width: 148px;
    cursor: pointer;

    @media (max-width: 768px) {
      max-width: 80px;
    }
  }
}
