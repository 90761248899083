@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import 'slick/slick';

//TYPOGRAPHY
@import 'typography/typography';

//BASE
@import 'variables/variables';
@import 'base/normalize';
@import 'base/base';
@import 'base/grid';
@import 'base/utilities';

//COMPONENTS
@import 'components/header';
@import 'components/buttons';
@import 'components/sidebar';
@import 'components/links';
@import 'components/info_bar';
@import 'components/cards';
@import 'components/modal';
@import 'components/inputs';
@import 'components/decorated_text';
@import 'components/step_title';
@import 'components/steps_nav';
@import 'components/timer';
@import 'components/contact_info';
@import 'components/chip';

//STEPS
@import 'steps/settings';
@import 'steps/suppliers';
@import 'steps/delivery';
@import 'steps/timing';
@import 'steps/checkout';
@import 'steps/thank_you';

//TEMP
@import 'temp/temp';
