.old-price {
  position: relative;
  line-height: 24px;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 3px;
    background: $red;
    transform: translateY(-50%) translateX(-50%) rotate(-40deg);
  }
}

.with-icon {
  display: inline-block;
  line-height: 18px;
  padding: 4px 0 4px 36px;

  &.distance {
    background: url('../../assets/icons/distance-light.svg') center left no-repeat;
  }

  &.delivery {
    background: url('../../assets/icons/delivery.svg') center left no-repeat;
  }

  &.delivery-grey {
    background: url('../../assets/icons/delivery-grey.svg') center left no-repeat;
    background-size: 26px;
  }

  &.business {
    background: url('../../assets/icons/business-input.svg') 0 no-repeat;
  }

  &.self-pickup {
    background: url('../../assets/icons/self-pickup.svg') 0 no-repeat;
    background-size: 26px;
  }

  &.quantity {
    background: url('../../assets/icons/quantity.svg') center left no-repeat;
  }

  &.location {
    background: url('../../assets/icons/location-light.svg') center left no-repeat;
  }

  &.location-current {
    background: url('../../assets/icons/location-current.svg') center left no-repeat;
  }

  &.invoice {
    padding: 4px 36px 4px 0;
    background: url('../../assets/icons/invoice.svg') center right no-repeat;
  }

  &.question {
    max-width: none;
    padding: 4px 36px 4px 0;
    background: url('../../assets/icons/question.svg') center right no-repeat;
  }
}
