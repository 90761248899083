.main-layout {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: initial;
}

.steps-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 352px);
  padding: 108px 0 220px 0;

  @media (max-width: 1200px) {
    width: calc(100% - 296px);
    padding: 108px 20px 160px 20px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }

  .step {
    position: relative;
    width: 100%;
    max-width: 790px;
    padding-bottom: 30px;

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex > * {
  box-sizing: border-box;
  flex-shrink: 0;
  max-width: 100%;
}

.col {
  position: relative;
  flex-direction: column;
}

.col > * {
  box-sizing: border-box;
}

.display-inline-flex {
  display: inline-flex;
}

.display-inline-block {
  display: inline-block;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: flex-end;
}

.nowrap {
  flex-wrap: nowrap;
}
