.modal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(23, 24, 24, 0.7);
  z-index: 2;

  &.open {
    display: flex;
  }

  .modal {
    background: #fff;
  }
}

.auth-modal {
  width: 100%;
  max-width: 454px;
  padding: 32px 38px;
  overflow-y: auto;

  @media (max-width: 520px) {
    max-width: none;
    height: 100vh;
  }

  .switch {
    width: 262px;
    padding: 6px;
    background: $light-grey-2;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
  }

  .form-tab {
    width: 100%;
    min-height: 568px;
    display: none;

    &.active {
      display: flex;
    }
  }
}

.info-modal {
  width: 100%;
  padding: 0 60px 32px 60px;

  @media (max-width: 768px) {
    padding: 0 20px 32px 30px;
  }

  ul {
    list-style: disc;

    li {
      list-style-position: inside;
    }
  }

  .modal-logo {
    width: 100px;

    @media (max-width: 520px) {
      width: 60px;
    }
  }

  .info-item {
    border-top: 1px solid $light-grey;

    &:last-of-type {
      border-bottom: 1px solid $light-grey;
    }

    .product-image {
      display: block;
      width: 140px;
      height: auto;
    }

    .content {
      max-width: calc(100% - 172px);

      @media (max-width: 480px) {
        max-width: 100%;
      }

      .left {
        max-width: 80%;
      }
      .right {
        max-width: 20%;
      }
    }
  }
}

.image-modal {
  width: 100%;
  padding: 0 50px 50px 50px;

  img {
    width: 100%;
    height: 60vh;
    object-fit: contain;

    @media (orientation: portrait) {
      height: 30vh;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px 48px 30px;
  }
}

.address-modal {
  .card {
    padding: 32px;

    @media (max-width: 768px) {
      padding: 16px;
    }
  }

  .card-content {
    margin-right: 12px;
    overflow-y: scroll;
    overflow-x: clip;
  }
}
