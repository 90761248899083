@import '../mixins/mixins';

.arrow-link {
  padding-right: 28px;
  background-image: url('../../assets/icons/arrow-green.svg');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 18px;
}

.icon-link {
  padding-left: 26px;

  &.user-name {
    background: url('../../assets/icons/user-icon.svg') center left no-repeat;
    background-size: calculateRem(22px);
  }
}

.nav-link {
  padding-right: 10px;

  @media (max-width: 380px) {
    font-size: 14px;
  }

  &.with-icon {
    padding: 3px 0 3px 40px;
  }

  &.location {
    background: url('../../assets/icons/location-large.svg') center left no-repeat;
  }

  &.phone {
    background: url('../../assets/icons/phone.svg') center left no-repeat;

    &.light {
      background: url('../../assets/icons/phone-light.svg') center left no-repeat;
    }
  }

  &.email {
    background: url('../../assets/icons/email.svg') center left no-repeat;

    &.light {
      background: url('../../assets/icons/email-light.svg') center left no-repeat;
    }
  }
}
