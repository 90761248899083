.timer {
  opacity: 0;
  position: absolute;
  top: 16px;
  right: 0;
  padding-left: 40px;
  background: url('../../assets/icons/clock-regular.svg') no-repeat;

  &.visible {
    opacity: 1;
  }
}
